<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar 
========================================================================================== -->


<template>
<div class="relative">
  <div class="vx-navbar-wrapper navbar-full p-0">
    <vs-navbar class="navbar-custom navbar-skelton" :class="navbarClasses"  :style="navbarStyle" :color="navbarColor">

      <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" />

      <router-link tag="div" to="/" class="vx-logo cursor-pointer mx-auto flex items-center">
        <logo class="w-10 mr-3 fill-current text-primary" />
        <span class="vx-logo-text text-primary">duotalk</span>
      </router-link>

      <i18n />

      <search-bar />

      <cart-drop-down />

      <notification-drop-down />

      <profile-drop-down />

    </vs-navbar>
  </div>
</div>
</template>

<script>
import Bookmarks            from "./components/Bookmarks.vue"
import ProfileDropDown      from "./components/ProfileDropDown.vue"
import Logo                 from "../Logo.vue"

export default {
  name: "the-navbar-horizontal",
  props: {
    logo: { type: String                                                                                                          },
    navbarType: {
      type: String,
      required: true
    }
  },
  components: {
    Logo,
    Bookmarks, 
    ProfileDropDown,
  },


}

</script>

<style scoped>
.vx-logo-text{
  font-family: "Maven Pro", sans-serif;
}
</style>
