<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.name">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.name }}</p>
      <small>Online</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <img
          v-if="activeUserInfo.profilePic"
          key="onlineImg"
          :src="activeUserInfo.profilePic"
          alt="user-img"
          width="40"
          height="40"
          class="rounded-full shadow-md cursor-pointer block"
        />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 15rem">
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/settings').catch(() => {})"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{ $t("YourProfile") }}</span>
          </li>

          <vs-divider  class="m-1" />
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/acc').catch(() => {})"
          >
            <feather-icon icon="PackageIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{ $t("YourAccount") }}</span>
          </li>


          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/acc/users').catch(() => {})"
          >
            <feather-icon icon="UsersIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{ $t("YourTeam") }}</span>
          </li>

          <li
            v-if="false"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/pages/invoice').catch(() => {})"
          >
            <feather-icon icon="CreditCardIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Pagamentos</span>
          </li>

          <vs-divider   class="m-1" />

          <li v-if="true"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="openFaq"
          >
            <feather-icon icon="HelpCircleIcon" svgClasses="w-4 h-4" />
            <span class="ml-2" >Central de Ajuda</span>
          </li>

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout()"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{$t("Logout")}}</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.user;
    }
  },
  methods: {
    openFaq() {
      window.open('https://ajuda.duotalk.io', '_blank')
    },
    logout() {
      this.$store.dispatch("auth/logOut");
    }
  }
};
</script>
