<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
========================================================================================== -->


<template>
    <footer class="the-footer flex-wrap justify-center mb-10">
        <div class="flex flex-col justify-center items-center">
            <span>Copyright &copy; {{ new Date().getFullYear() }} <a href="https://duotalk.io" target="_blank" rel="nofollow">duotalk</a>, todos os direitos reservados. v{{ appVersion }}</span>
        </div>
    </footer>
</template>

<script>
export default {
    props: {
        appVersion: {
            type: String,
            required: false,
            default: () => "0",
        }
    }
}
</script>
