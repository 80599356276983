<!-- =========================================================================================
    File Name: VerticalNavMenuItem.vue
    Description: Vertical NavMenu item component. Extends vuesax framework's 'vs-sidebar-item' component
    Component Name: VerticalNavMenuItem

========================================================================================== -->

<template>
  <div
    v-if="canSee"
    class="vs-sidebar--item w-full"
    :class="[
      { 'vs-sidebar-item-active': activeLink },
      { 'disabled-item pointer-events-none': isDisabled },
    ]"
  >
    <router-link
      tabindex="0"
      v-if="to"
      exact
      :class="[{ 'router-link-active': activeLink }]"
      :to="to"
      :target="target"
    >
      <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon" />
      <feather-icon v-else :class="{ 'w-3 h-3': iconSmall }" :icon="icon" />
      <slot />
    </router-link>

    <a v-else :target="target" :href="href" tabindex="-1">
      <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon" />
      <feather-icon v-else :class="{ 'w-3 h-3': iconSmall }" :icon="icon" />
      <slot />
    </a>
  </div>
</template>

<script>
export default {
  name: "v-nav-menu-item",
  props: {
    icon: { type: String, default: "" },
    iconSmall: { type: Boolean, default: false },
    iconPack: { type: String, default: "material-icons" },
    href: { type: [String, null], default: "#" },
    to: { type: [String, Object, null], default: null },
    slug: { type: String, default: null },
    index: { type: [String, Number], default: null },
    featherIcon: { type: Boolean, default: true },
    target: { type: String, default: "_self" },
    isDisabled: { type: Boolean, default: false },
    planCheck: { type: Object, default: null },
  },
  computed: {
    canSee() {
      const hasAclPermission = this.$acl.check(this.$router.match(this.to).meta.rule);
      if (this.planCheck !== null) {
        if(this.slug === 'contacts' && this.$store.state.acc.current_acc.blockContactTab && this.$store.state.user.userRole === 'operator')
          return false
        if (this.planCheck.comparison === 'eq')
          return (
            this.$store.state.acc.current_acc.accPlan.plan.planParameters[
              this.planCheck.value
            ] === true && hasAclPermission
          );
        else if (this.planCheck.comparison === 'ne')
          return (
            this.$store.state.acc.current_acc.accPlan.plan.planParameters[
              this.planCheck.value
            ] !== true && hasAclPermission
          );
      }

      return this.to
        ? this.$acl.check(this.$router.match(this.to).meta.rule)
        : true;
    },
    activeLink() {
      return this.to == this.$route.path ||
        (this.$route.meta.parent == this.slug && this.to)
        ? true
        : false;
    },
  },
};
</script>

<style lang="scss">
.header-sidebar {
  padding: 20px 19px 16px 13px !important;
}
</style>

