<template>
  <div class="w-full flex zindex-5000">
    <v-select
      id="select-nav"
      style="min-width: 30vw"
      :value="accSelected"
      :clearable="false"
      :options="$store.state.acc.accounts"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
      class="w-full"
      @input="setAcc"
      :filter="searchAccount"
    />
    <vs-button
      v-if="$acl.check('master')"
      @click="popupMasterActive = true"
      active.sync="popupMasterActive"
      class="ml-4"
      color="primary"
      type="border"
      icon-pack="feather"
      icon="icon-plus"
    />
    <vs-popup
      class="holamundo"
      title="Criar nova conta"
      :active.sync="popupMasterActive"
    >
      <div class="flex flex-wrap">
        <div class="vx-col w-full mt-4">
          <vs-input
            data-vv-validate-on="blur"
            v-validate="'required|min:3|max:30'"
            name="companyName"
            v-model="newAccName"
            class="w-full"
            :label="$t('CompanyName')"
            danger-text="1|2|3"
          />
          <span class="text-danger text-sm">{{
            errors.first("companyName")
          }}</span>
        </div>

        <div class="vx-col w-full mt-4">
          <label for="" class="vs-input--label">{{
            $t("CompanyIndustry")
          }}</label>
          <v-select
            v-model="newAccCompanyIndustry"
            :clearable="false"
            :options="segmentOptions"
            name="segment"
            class="w-full"
            :filter="searchSegment"
          />
        </div>

        <div class="vx-col w-full mt-4">
          <label for="" class="vs-input--label">{{
            $t("CompanyEmployees")
          }}</label>
          <v-select
            v-model="newAccCompanyEmployeeCount"
            :clearable="false"
            :options="companyEmployeesOptions"
            name="companyEmployees"
            class="w-full"
            :filter="searchSegment"
          />
        </div>

        <div class="vx-col w-full mt-4">
          <label for="" class="vs-input--label">{{ $t("Plan") }}</label>
          <v-select
            v-model="newAccPlan"
            :clearable="false"
            :options="plansOptions"
            name="segment"
            class="w-full"
          />
        </div>

        <div class="vx-col w-full mt-4">
          <vs-button
            @click="createAcc()"
            class="w-40 w-full"
            color="success"
            type="filled"
            >Criar</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import normalizeString from "@/helpers/normalizeString"

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      popupMasterActive: false,
      plansOptions: [],
      newAccPlan: null,
      newAccName: null,
      newAccCompanyIndustry: null,
      segmentOther: "",
      segmentOptions: [
        {
          label: "Automotivo",
          value: "automotivo",
        },
        {
          label: "Agência",
          value: "agencia",
        },
        {
          label: "Beleza",
          value: "beleza",
        },
        {
          label: "B2B",
          value: "b2b",
        },
        {
          label: "Imobiliária",
          value: "imobiliaria",
        },
        {
          label: "Produtos",
          value: "produtos",
        },
        {
          label: "Restaurante",
          value: "restaurante",
        },
        {
          label: "Saúde",
          value: "saude",
        },
        {
          label: "Serviços",
          value: "servicos",
        },
        {
          label: "Startup",
          value: "startup",
        },
        {
          label: "Outros",
          value: "outros",
        },
      ],
      newAccCompanyEmployeeCount: null,
      companyEmployees: { value: "" },
      companyEmployeesOptions: [
        {
          label: "Até 3 atendentes",
          value: "up-to-3",
        },
        {
          label: "Até 5 atendentes",
          value: "up-to-5",
        },

        {
          label: "Até 10 atendentes",
          value: "up-to-10",
        },
        {
          label: "Mais de 10 atendentes",
          value: "10+",
        },
      ],
    };
  },
  computed: {
    accSelected() {
      return {
        id: this.$store.state.acc.current_acc.id,
        label: this.$store.state.acc.current_acc.name,
      };
    },
  },
  methods: {
    setAcc(v) {
      this.$vs.loading();
      this.$router.replace({ path: this.$router.path, query: { acc: v.id } });
      this.$store
        .dispatch("acc/setCurrentAccount", { acc: v.id, accChange: true })
        .then(() => {
          this.$vs.loading.close();
          window.location.replace("/");
        });
    },
    createAcc() {
      this.$store
        .dispatch("acc/createAcc", {
          plan: this.newAccPlan.value,
          name: this.newAccName,
          companyIndustry: this.newAccCompanyIndustry.value,
          companyEmployeeCount: this.newAccCompanyEmployeeCount.value,
          whitelabel: "default",
        })
        .then(() => {
          alert("CONTA CRIADA COM SUCESSO");
          window.location.replace("/");
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;

          this.$vs.notify({
            time: 2500,
            title: this.$t("Error"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
    getPlans() {
      var self = this;
      //fetchGetPlans
      const payload = {
        product: "5e4c20ee2767d32b33eaa12e",
        whitelabel: this.$store.state.acc.current_acc.whitelabel,
        notify: this.$vs.notify,
      };
      this.$store
        .dispatch("auth/fetchGetPlans", payload)
        .then((result) => {
          for (var i = 0; i < result.data.data.length; i++) {
            if (result.data.data[i].listEditing)
              self.plansOptions.push({
                label: result.data.data[i].title,
                value: result.data.data[i]._id,
              });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: this.$t("Error"),
            text: error.message || error.data[0].msg,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    },
    searchAccount(options, search){
      return options.filter(item => normalizeString(item.label).includes(normalizeString(search)))
    },
    searchSegment(options, search){
      return options.filter(item =>
        normalizeString(item.label).includes(normalizeString(search)) ||
        normalizeString(item.value).includes(normalizeString(search)))
    }
  },
  mounted() {
    this.$store.dispatch("acc/getAccounts");
    if (this.$acl.check("master")) this.getPlans();
  },
};
</script>

<style lang="scss">
#select-nav {
  .vs__search {
    margin-top: -30px;
  }
  input.vs__search:focus {
    padding-top: 30px !important;
  }
  span.vs__selected {
    width: 80px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    display: inline-block !important;
    text-overflow: ellipsis !important;
  }
}

@media (min-width: 768px) {
  #select-nav {
    .vs__search {
      margin-top: 4px;
    }
    input.vs__search:focus {
      padding: 0 7px !important;
    }
    span.vs__selected {
      width: 290px !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      display: inline-block !important;
      text-overflow: ellipsis !important;
    }
  }
}
button.vs__clear {
  display: none !important;
}
.zindex-5000 {
  position: relative !important;
  z-index: 50000 !important;
}
</style>
