<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
========================================================================================== -->

<template>
  <div
    class="layout--main"
    :class="[
      layoutTypeClass,
      navbarClasses,
      footerClasses,
      { 'no-scroll': isAppPage },
    ]"
  >
    <the-customizer
      v-if="disableCustomizer"
      :footerType="footerType"
      :hideScrollToTop="hideScrollToTop"
      :navbarType="navbarType"
      :navbarColor="navbarColor"
      :routerTransition="routerTransition"
      @toggleHideScrollToTop="toggleHideScrollToTop"
      @updateFooter="updateFooter"
      @updateNavbar="updateNavbar"
      @updateNavbarColor="updateNavbarColor"
      @updateRouterTransition="updateRouterTransition"
    />

    <v-nav-menu
      :navMenuItems="navMenuItems"
      title="duotalk"
      parent=".layout--main"
    />

    <div
      id="content-area"
      :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]"
    >
      <div id="content-overlay" />

      <!-- Navbar -->
      <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
        <the-navbar-horizontal
          :navbarType="navbarType"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
        />

        <div style="height: 62px" v-if="navbarType === 'static'"></div>

        <h-nav-menu
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
          :navMenuItems="navMenuItems"
        />
      </template>

      <template
        v-if="
          mainLayoutType === 'vertical' && $router.currentRoute.name != 'inbox'
        "
      >
        <the-navbar-vertical
          :navbarColor="navbarColor"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
        />
      </template>
      <!-- /Navbar -->

      <div class="content-wrapper ml-1">
        <div class="router-view">
          <div class="router-content">
            <div class="content-area__content">
              <back-to-top
                bottom="5%"
                :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'"
                visibleoffset="500"
                v-if="!hideScrollToTop"
              >
                <vs-button
                  icon-pack="feather"
                  icon="icon-arrow-up"
                  class="shadow-lg btn-back-to-top"
                />
              </back-to-top>

              <transition :name="routerTransition" mode="out-in">
                <router-view
                  @changeRouteTitle="changeRouteTitle"
                  @setAppClasses="
                    (classesStr) => $emit('setAppClasses', classesStr)
                  "
                />
              </transition>
            </div>
          </div>
        </div>
      </div>

      <the-footer
        v-if="$route.name !== 'inbox'"
        :appVersion="$store.state.appVersion"
      />
    </div>

    <vs-prompt type="confirm" :buttons-hidden="true" :active.sync="updatePopup" color="dark" title="Atualizar dados">
      <vs-row class="p-6">
        <vs-col vs-w="12" vs-type="flex" vs-justify="center">
          <p class="text-center">
            <span class="text-3xl">Olá! 👋</span><br/>
            Antes de prosseguir, precisamos que <b>atualize</b><br/>
            os seus dados abaixo:
          </p>
        </vs-col>
        <vs-col vs-w="12" class="mt-5" vs-type="flex" vs-justify="center">
          <vs-input
            :label="$t('Name')"
            v-model="name"
            class="w-full"
            :danger="name.length < 3"
            danger-text="O nome precisa ter mais de 3 caracteres."
            val-icon-danger="icon-x"
            val-icon-pack="feather"
          />
        </vs-col>
        <vs-col vs-w="12" class="mt-4" vs-type="flex" vs-justify="center">
          <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
            <label class="vs-input--label">{{$t('Phone')}}</label>
            <vue-tel-input
              v-model="phone"
              class="w-full"
              @validate="validatePhone"
            />
            <div v-if="!validPhone" class="con-text-validation span-text-validation-danger vs-input--text-validation-span">
              <span class="span-text-validation">Insira um telefone válido.</span>
            </div>
          </div>
        </vs-col>
        <vs-col class="text-center mt-5">
          <vs-button
            class="w-full"
            @click="confirmUserUpdateInfo"
            :disabled="!validPhone && name.length < 3"
          >{{$t('Confirm')}}</vs-button>
        </vs-col>
      </vs-row>
    </vs-prompt>
  </div>
</template>

<script>
import BackToTop from "vue-backtotop";
import HNavMenu from "@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue";
import navMenuItems from "@/layouts/components/vertical-nav-menu/navMenuItems.js";
import TheNavbarHorizontal from "@/layouts/components/navbar/TheNavbarHorizontal.vue";
import TheNavbarVertical from "@/layouts/components/navbar/TheNavbarVertical.vue";
import TheFooter from "@/layouts/components/TheFooter.vue";
import themeConfig from "@/../themeConfig.js";
import VNavMenu from "@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue";

export default {
  components: {
    BackToTop,
    HNavMenu,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
    /*VxTour*/
  },
  data() {
    return {
      pendingChats: false,
      disableCustomizer: themeConfig.disableCustomizer,
      disableThemeTour: themeConfig.disableThemeTour,
      dynamicWatchers: {},
      footerType: themeConfig.footerType || "static",
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || "#fff",
      navbarType: themeConfig.navbarType || "floating",
      routerTransition: themeConfig.routerTransition || "none",
      routeTitle: this.$route.meta.pageTitle,
      steps: [
        {
          target: ".vs-con-dropdown",
          content:
            "Create your own bookmarks. You can also re-arrange them using drag & drop.",
        },
        {
          target: ".vs-sidebar--item",
          content: "You can change language from here.",
        },
        {
          target: ".vx-card__collapsible-content",
          content: "Try fuzzy search to visit pages in flash.",
        },
      ],
      updatePopup: false,
      name: "",
      phone: "",
      validPhone: true,
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
    isThemeDark(val) {
      const color = this.navbarColor === "#fff" && val ? "#10163a" : "#fff";
      this.updateNavbarColor(color);
    },
    "$store.state.mainLayoutType"(val) {
      this.setNavMenuVisibility(val);
      this.disableThemeTour = true;
    },
  },
  computed: {
    navMenuItems() {
      return navMenuItems;
    },
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },
    contentAreaClass() {
      if (this.mainLayoutType === "vertical") {
        if (this.verticalNavMenuWidth === "default")
          return "content-area-reduced";
        else if (this.verticalNavMenuWidth === "reduced")
          return "content-area-lg";
        else return "content-area-full";
      }
      else return "content-area-full";
    },
    footerClasses() {
      return {
        "footer-hidden": this.footerType === "hidden",
        "footer-sticky": this.footerType === "sticky",
        "footer-static": this.footerType === "static",
      };
    },
    isAppPage() {
      return this.$route.meta.no_scroll;
    },
    isThemeDark() {
      return this.$store.state.theme === "dark";
    },
    layoutTypeClass() {
      return `main-${this.mainLayoutType}`;
    },
    mainLayoutType() {
      return this.$store.state.mainLayoutType;
    },
    navbarClasses() {
      return {
        "navbar-hidden": this.navbarType === "hidden",
        "navbar-sticky": this.navbarType === "sticky",
        "navbar-static": this.navbarType === "static",
      };
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbar(val) {
      if (val === "static")
        this.updateNavbarColor(this.isThemeDark ? "#10163a" : "#fff");
      this.navbarType = val;
    },
    updateNavbarColor(val) {
      this.navbarColor = val;
      this.isNavbarDark = val !== "#fff";
    },
    updateFooter(val) {
      this.footerType = val;
    },
    updateRouterTransition(val) {
      this.routerTransition = val;
    },
    setNavMenuVisibility(layoutType) {
      if (
        (layoutType === "horizontal" && this.windowWidth >= 1200) ||
        (layoutType === "vertical" && this.windowWidth < 1200)
      ) {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
        this.$store.dispatch("updateVerticalNavMenuWidth", "no-nav-menu");
      } else {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val;
    },
    async confirmUserUpdateInfo() {
      const response = await this.$http({
        method: "PUT",
        url: '/g/user/' + this.$store.state.user._id,
        data: {
          name: this.name,
          phone: this.phone,
          basicInformationHasUpdated: true
        }
      })

      if(response.status === 200) {
        this.updatePopup = false

        this.$vs.notify({
          title: this.$t("Success"),
          text: this.$t("InformationChanged"),
          iconPack: "feather",
          icon: "icon-success-circle",
          color: "success",
          position: "top-right",
          time: 4000,
        })
      }
    },
    validatePhone(e) {
      this.validPhone = true;
      if (this.phone.slice(0, 2) === "55") {
        this.validPhone = e.valid;
      }
    },
  },
  async beforeMount() {
    const response = await this.$http({
      method: "GET",
      url: '/g/user/' + this.$store.state.user._id
    })

    if (response.status === 200 && response.data.data.basicInformationHasUpdated) {
      this.updatePopup = false
    } else {
      this.updatePopup = true

      this.name = this.$store.state.user.name
      this.phone = this.$store.state.user.phone
    }
  },
  created() {
    const color =
      this.navbarColor === "#fff" && this.isThemeDark
        ? "#10163a"
        : this.navbarColor;
    this.updateNavbarColor(color);
    this.setNavMenuVisibility(this.$store.state.mainLayoutType);

    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch(
      "$store.state.windowWidth",
      (val) => {
        if (val < 1200) {
          this.disableThemeTour = true;
          this.dynamicWatchers.windowWidth();
        }
      }
    );

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch(
      "$store.state.verticalNavMenuWidth",
      () => {
        this.disableThemeTour = true;
        this.dynamicWatchers.verticalNavMenuWidth();
      }
    );

    this.dynamicWatchers.rtl = this.$watch("$vs.rtl", () => {
      this.disableThemeTour = true;
      this.dynamicWatchers.rtl();
    });
  },
  beforeDestroy() {
    Object.keys(this.dynamicWatchers).map((i) => {
      this.dynamicWatchers[i]();
      delete this.dynamicWatchers[i];
    });
  },
};
</script>

<style>
.uf-modal-inner {
  max-height: calc(100vh - 100px) !important;
  overflow: auto !important;
}
.uf-content {
  max-height: 100% !important;
}
</style>
