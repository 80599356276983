<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown
    ref="notdropdown"
    vs-custom-content
    vs-trigger-click
    class="cursor-pointer"
  >
    <vs-button
      class="flex-inline mr-4"
      color="primary"
      type="border"
      icon-pack="feather"
      icon="icon-bell"
      size="small"
    >
      <div v-if="unreadNotificationsCount > 0">
        {{ unreadNotificationsCount }}
      </div>
    </vs-button>

    <vs-dropdown-menu
      class="notification-dropdown dropdown-custom vx-navbar-dropdown"
    >
      <div class="notification-top text-center p-5 bg-primary text-white">
        <h3 v-if="notifications.length > 0" class="text-white">
          {{ unreadNotificationsCount }} Nova{{
            unreadNotificationsCount > 1 ? "s" : ""
          }}
        </h3>
        <h3 v-else class="text-white">Nenhuma mensagem</h3>
        <p v-if="notifications.length > 0" class="opacity-75">
          App Notifications
        </p>
      </div>

      <VuePerfectScrollbar
        v-if="notifications.length > 0"
        ref="mainSidebarPs"
        class="scroll-area--nofications-dropdown p-0 mb-10"
        :settings="settings"
        :key="$vs.rtl"
      >
        <ul class="bordered-items">
          <li
            :key="ntf._id"
            @click="
              ntf.data.survey
                ? toFeedback(ntf._id)
                : toConversation(ntf.data.conversationID, ntf._id)
            "
            v-for="ntf in notifications"
            class="flex justify-between px-4 py-4 notification cursor-pointer"
            :class="!ntf.read ? 'bg-grey-light font-semibold' : ''"
          >
            <div class="flex items-start">
              <feather-icon
                :icon="ntf.icon"
                :svgClasses="[
                  `text-${ntf.category}`,
                  'stroke-current mr-1 h-6 w-6',
                ]"
              ></feather-icon>
              <div class="mx-2">
                <span
                  class="font-medium block notification-title"
                  :class="[`text-${ntf.category}`]"
                  >{{ ntf.title }}</span
                >
                <div class="flex flex-row items-center" v-if="ntf.data.survey">
                  <h1
                    v-if="ntf.data.survey.type === 'NPS'"
                    class="text-xl"
                    :class="{
                      'text-danger': ntf.data.survey.score <= 6,
                      'text-grey':
                        ntf.data.survey.score > 6 && ntf.data.survey.score < 9,
                      'text-success': ntf.data.survey.score > 8,
                    }"
                  >
                    {{ ntf.data.survey.score }}/10
                  </h1>
                  <feather-icon
                    v-if="
                      ntf.data.survey.type === 'CSAT' &&
                      ntf.data.survey.score == 0
                    "
                    icon="FrownIcon"
                    svgClasses="w-8 h-8 text-danger"
                  />
                  <feather-icon
                    v-if="
                      ntf.data.survey.type === 'CSAT' &&
                      ntf.data.survey.score == 1
                    "
                    icon="MehIcon"
                    svgClasses="w-8 h-8"
                  />
                  <feather-icon
                    v-if="
                      ntf.data.survey.type === 'CSAT' &&
                      ntf.data.survey.score == 2
                    "
                    icon="SmileIcon"
                    svgClasses="w-8 h-8 text-success"
                  />
                  <p class="ml-3" v-if="ntf.data.survey.type === 'CSAT'">
                    {{ ntf.data.conversationName }}
                  </p>
                </div>
                <template v-else>
                  <small>{{ ntf.msg }}</small>
                </template>
              </div>
            </div>
            <small class="mt-1 whitespace-no-wrap">{{
              elapsedTime(ntf.timestamp)
            }}</small>
          </li>
        </ul>
      </VuePerfectScrollbar>

      <div
        v-if="notifications.length > 0"
        class="checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer"
      >
        <span @click="markAll">Marcar todas as notificações como lidas</span>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import { onMessage } from "@firebase/messaging";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { messaging } from "@/plugins/firebase";

export default {
  name: "NotificationDropDown",
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      notifications: [],
      unreadNotificationsCount: 0,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  async mounted() {
    this.getNotifications();
    if (process.env.VUE_APP_FIREBASE_ENABLED === "true") {
      onMessage(messaging, (payload) => {
        this.getNotifications();
        this.$vs.notify({
          title: payload.notification.title,
          text: payload.notification.body,
          iconPack: "feather",
          icon: "icon-success-circle",
          color: "primary",
          position: "top-right",
          time: 4000,
          click: async () => {
            const _data = payload.data;

            this.$http
              .post(`/g/user/notification/update`, {
                acc: this.$store.state.acc.current_acc.id,
                notificationID: _data.notificationID,
              })
              .then(() => {
                this.getNotifications();
                if (_data.survey) {
                  this.toFeedback(_data.conversationID);
                } else {
                  this.toConversation(_data.conversationID);
                }
              });
          },
        });
      });
    }
  },
  methods: {
    async toFeedback(id) {
      if (id != undefined) {
        this.$http.post(`/g/user/notification/update`, {
          acc: this.$store.state.acc.current_acc.id,
          notificationID: id,
        });
      }
      await this.$router.push({
        name: "feedback",
      });
      window.location.reload();
    },
    async markAll() {
      await this.$http.post(`/g/user/notification/update`, {
        acc: this.$store.state.acc.current_acc.id,
        notificationID: "all",
      });

      this.notifications = [];
      this.getNotifications();
    },
    toConversation(conversationID, id) {
      if (id != undefined) {
        this.$http.post(`/g/user/notification/update`, {
          acc: this.$store.state.acc.current_acc.id,
          notificationID: id,
        });
      }

      this.$router.push({
        name: "inbox",
        query: {
          conversation: conversationID,
          notification: true,
        },
      });
    },
    async getNotifications() {
      const response = await this.$http.get(
        `/p/chat/inbox/${this.$store.state.acc.current_acc.id}`,
        {
          params: { acc: this.$store.state.acc.current_acc.id },
        }
      );
      this.notifications = response.data.data.notifications;
      this.unreadNotificationsCount = this.notifications.filter(
        (not) => !not.read
      ).length;

      await this.$store.dispatch(
        "chat/setRestrictOperator",
        response.data.data.restrictOperator === true
      );
    },
    elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? " Anos " : " Ano ") + "atrás";
      } else if (days > 0) {
        return days + (days > 1 ? " Dias " : " Dia ") + "atrás";
      } else if (hours > 0) {
        return hours + (hours > 1 ? " Hrs " : " Hour ") + "atrás";
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? " Mins " : " Min ") + "atrás";
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? " Seg " : " Seg ") + "atrás";
      }

      return "Agora";
    },
  },
};
</script>
