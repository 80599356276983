var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-dropdown',{ref:"notdropdown",staticClass:"cursor-pointer",attrs:{"vs-custom-content":"","vs-trigger-click":""}},[_c('vs-button',{staticClass:"flex-inline mr-4",attrs:{"color":"primary","type":"border","icon-pack":"feather","icon":"icon-bell","size":"small"}},[(_vm.unreadNotificationsCount > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.unreadNotificationsCount)+" ")]):_vm._e()]),_c('vs-dropdown-menu',{staticClass:"notification-dropdown dropdown-custom vx-navbar-dropdown"},[_c('div',{staticClass:"notification-top text-center p-5 bg-primary text-white"},[(_vm.notifications.length > 0)?_c('h3',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.unreadNotificationsCount)+" Nova"+_vm._s(_vm.unreadNotificationsCount > 1 ? "s" : "")+" ")]):_c('h3',{staticClass:"text-white"},[_vm._v("Nenhuma mensagem")]),(_vm.notifications.length > 0)?_c('p',{staticClass:"opacity-75"},[_vm._v(" App Notifications ")]):_vm._e()]),(_vm.notifications.length > 0)?_c('VuePerfectScrollbar',{key:_vm.$vs.rtl,ref:"mainSidebarPs",staticClass:"scroll-area--nofications-dropdown p-0 mb-10",attrs:{"settings":_vm.settings}},[_c('ul',{staticClass:"bordered-items"},_vm._l((_vm.notifications),function(ntf){return _c('li',{key:ntf._id,staticClass:"flex justify-between px-4 py-4 notification cursor-pointer",class:!ntf.read ? 'bg-grey-light font-semibold' : '',on:{"click":function($event){ntf.data.survey
              ? _vm.toFeedback(ntf._id)
              : _vm.toConversation(ntf.data.conversationID, ntf._id)}}},[_c('div',{staticClass:"flex items-start"},[_c('feather-icon',{attrs:{"icon":ntf.icon,"svgClasses":[
                `text-${ntf.category}`,
                'stroke-current mr-1 h-6 w-6',
              ]}}),_c('div',{staticClass:"mx-2"},[_c('span',{staticClass:"font-medium block notification-title",class:[`text-${ntf.category}`]},[_vm._v(_vm._s(ntf.title))]),(ntf.data.survey)?_c('div',{staticClass:"flex flex-row items-center"},[(ntf.data.survey.type === 'NPS')?_c('h1',{staticClass:"text-xl",class:{
                    'text-danger': ntf.data.survey.score <= 6,
                    'text-grey':
                      ntf.data.survey.score > 6 && ntf.data.survey.score < 9,
                    'text-success': ntf.data.survey.score > 8,
                  }},[_vm._v(" "+_vm._s(ntf.data.survey.score)+"/10 ")]):_vm._e(),(
                    ntf.data.survey.type === 'CSAT' &&
                    ntf.data.survey.score == 0
                  )?_c('feather-icon',{attrs:{"icon":"FrownIcon","svgClasses":"w-8 h-8 text-danger"}}):_vm._e(),(
                    ntf.data.survey.type === 'CSAT' &&
                    ntf.data.survey.score == 1
                  )?_c('feather-icon',{attrs:{"icon":"MehIcon","svgClasses":"w-8 h-8"}}):_vm._e(),(
                    ntf.data.survey.type === 'CSAT' &&
                    ntf.data.survey.score == 2
                  )?_c('feather-icon',{attrs:{"icon":"SmileIcon","svgClasses":"w-8 h-8 text-success"}}):_vm._e(),(ntf.data.survey.type === 'CSAT')?_c('p',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(ntf.data.conversationName)+" ")]):_vm._e()],1):[_c('small',[_vm._v(_vm._s(ntf.msg))])]],2)],1),_c('small',{staticClass:"mt-1 whitespace-no-wrap"},[_vm._v(_vm._s(_vm.elapsedTime(ntf.timestamp)))])])}),0)]):_vm._e(),(_vm.notifications.length > 0)?_c('div',{staticClass:"checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer"},[_c('span',{on:{"click":_vm.markAll}},[_vm._v("Marcar todas as notificações como lidas")])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }